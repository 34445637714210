<template>
  <worker title="在职员工信息管理" :fun-auth-other="funAuth" isDiscipline="no"></worker>
</template>

<script>
import Worker from './worker'

export default {
  components: { Worker },
  computed: {
    funAuth () {
      return this.$authFunsProxy
    }
  }
}
</script>
